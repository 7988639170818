import React, { Component } from 'react';
import '../style/app.css';
import { ReactSortable } from "react-sortablejs";
import AudioPreview from "../AudioPreview";
import {downloadBook, generateBookManifest} from './DownloadAudioBook';
import {getUUIDFileName} from "../utils";
import AudioChapterItem from "./AudioChapterItem";
import JSZip from "jszip";

class AudioBook extends Component {

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      title: "",
      audioShowing: false,
    };
    this.counter = 0;
    this.audioPreviewRef = React.createRef();
  }

  onSelectAudioFiles = (e) => {
    const { items } = this.state;
    for (let file of e.target.files){
      items.push({
        id: this.counter++,
        name: getUUIDFileName(file),
        file: file,
        text: "",
        images: [],
        preview: false,
      });
    }
    e.target.value = ''
    this.setState({items});
  }

  changeTitle = (e) => {
    this.setState({title: e.target.value})
  }

  download = (preview) => {
    const {items, title} = this.state;
    downloadBook(title, items, preview).then(r => {

    });
  }

  onPlayAudioFile = (file) => {
    this.setState({audioShowing: true});
  }

  onHidePlayer = () => {
    this.setState({audioShowing: false});
    this.audioPreviewRef.current.stop();
  }

  onDeleteItem = (item) => {
    const {items} = this.state;
    const newItems = items.filter((it) => it !== item)
    this.setState({items: newItems})
  }

  addEmptyChapter = (item) => {
    const { items } = this.state;
    items.push({
      id: this.counter++,
      name: null,
      file: null,
      text: "",
      images: [],
      preview: false,
    });
    this.setState({items});
  }

  onChangeItems = () => {
    const { items } = this.state;
    this.setState({items});
  }

  onSelectExistingBook = async (e) => {
    const zip = await JSZip.loadAsync(e.target.files[0]);
    const manifest = JSON.parse(await zip.file("manifest.json").async('text'))
    const items = []
    for (let chapter of manifest.chapters){
      const filePath = (chapter.audios.length > 0 && chapter.audios[0].file) ? chapter.audios[0].file : null
      const name = filePath ? filePath.replace('audios/', '') : null
      let audioFile = null
      if (filePath){
        audioFile = await zip.file(filePath).async('blob')
        audioFile.name = name
      }

      const images = []
      if (chapter.audios.length > 0){
        for(let image of chapter.audios[0].images){
          console.log(image)
          images.push({
            id: this.counter++,
            name: image.image.replace('images/', ''),
            file: await zip.file(image.image).async('blob'),
            time: image.start,
          });
        }
      }

      items.push({
        id: this.counter++,
        name: name,
        file: audioFile,
        text: chapter.title,
        images: images,
        preview: false,
      });
    }
    this.setState({title: manifest.title, items: items});
    //e.target.value = null
  }

  resetBook = () => {
    this.setState({items: [], title: "", audioShowing: false})
  }

  render() {
    const {audioShowing, title} = this.state;
    return (
      <div className="App">
        <div className="audio-holder" style={{'display': audioShowing ? 'block': 'none'}}>
          <AudioPreview ref={this.audioPreviewRef} title="" onHide={this.onHidePlayer} minimizeOnHide={false} />
          <div className="help">
            Click and drag to make a region. Double click on a region to play it. Single click to seek.
          </div>
        </div>
        <div className="content">
          <div className="main-help">
            <h3>How to</h3>
            <ul>
              <li>Select all the audio files using the audio chooser.</li>
              <li>Add corresponding images to show while playing the audio and add the start time of showing each image.</li>
              <li>To add the time automatically, click play on the audio file name, then on the wave, select a region by dragging.
                Then click on the input box below the image. The input box will be filled in with the start time of the selected audio region.
              </li>
              <li>Fill in chapter names and the book name</li>
              <li>Click on "Download". This is the final book.</li>
            </ul>
          </div>
          {this.state.items.length > 0 && (
            <div className="pure-form">
              <input className="book-name" type="text" value={title} onChange={this.changeTitle} placeholder="Book Title"/>
            </div>
          )}
          <div className="page-items">
            <ReactSortable
              list={this.state.items}
              handle=".handle"
              setList={newState => this.setState({ items: newState })}
            >
              {this.state.items.map(item => (
                <AudioChapterItem
                  key={item.id}
                  item={item}
                  audioRef={this.audioPreviewRef}
                  onPlayAudio={this.onPlayAudioFile}
                  onChangeItems={this.onChangeItems}
                  onDelete={() => this.onDeleteItem(item)}/>
              ))}
            </ReactSortable>
          </div>
          <div className="chooser-holder">
            <div className="main-audio-file chooser">
              <div>Add audio files</div>
              <input multiple type="file" accept="audio/mpeg,audio/mp3,audio/wav" onChange={this.onSelectAudioFiles}/>
            </div>
            <div className="main-audio-file chooser">
              <div>Empty chapter</div>
              <button style={{fontSize: '12px'}} onClick={this.addEmptyChapter}>Add Empty Chapter</button>
            </div>
            <div className="main-audio-file chooser">
              <div>Load Existing Book</div>
              <input multiple type="file" accept="application/zip" onChange={this.onSelectExistingBook}/>
            </div>
          </div>
          { this.state.items.length > 0 && (
            <div>
              <button className="pure-button pure-button-primary download-button" onClick={() => this.download(false)}>Download Book</button>
              <button className="pure-button download-button" onClick={() => this.download(true)}>Download Preview</button>
              <button className="pure-button download-button button-warning" onClick={() => this.resetBook()}>Reset</button>
            </div>
          )}
        </div>
      </div>
    );
  }
}


export default AudioBook;
