import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import KidsBook from "./kids/KidsBook";
import Home from "./Home";
import AudioBook from "./audio/AudioBook";

export default function App() {
  return (
      <Router>
          <Switch>
              <Route path="/kids" component={KidsBook} />
              <Route path="/audio" component={AudioBook} />
              <Route path="/" component={Home} />
          </Switch>
      </Router>
  );
}
