import JSZip from "jszip";
import { saveAs } from 'file-saver';


function generateChapterManifest(items, mainAudioFile, wordsAudioFile) {


  let chapter = {
    play: "manual",
    voice: {},
    items: []
  }

  for (const item of items){
      let image = {
        image: "images/" + item.name,
        background: "#ffffff",
        text: item.text,
        points: 1
      };

      if (mainAudioFile && item.audio && item.audio.start !== undefined && item.audio.end !== undefined){
        image['voice'] = {
          source: "main",
          type: "recorded",
          start: item.audio.start.toFixed(3),
          end: item.audio.end.toFixed(3),
        };
      }

      image['words'] = {
        type: "tts"
      };

      chapter.items.push(image);
  }

  if (mainAudioFile != null){
    chapter["voice"]["main"] = {
      "type": "recorded",
      "file": "audio/" + mainAudioFile.name
    }
  }
  if (wordsAudioFile != null){
    chapter["voice"]["words"] = {
      "type": "recorded",
      "file": "audio/" + wordsAudioFile.name
    }
  }
  return chapter;
}


function downloadBook(items, mainAudioFile, wordsAudioFile) {
  
  const zip = new JSZip();
    let img = zip.folder("images");

    for (let item of items){
      img.file(item.name, item.file);
    }

    if (mainAudioFile != null || wordsAudioFile != null){
      let audio = zip.folder("audio");
      if (mainAudioFile != null){
        audio.file(mainAudioFile.name, mainAudioFile.file);
      }
      if (wordsAudioFile != null){
        audio.file(wordsAudioFile.name, mainAudioFile.file);
      }
    }
    const book = {
      chapters: [generateChapterManifest(items, mainAudioFile, wordsAudioFile)]
    }

    zip.file("manifest.json", JSON.stringify(book, null, '\t'));

    zip.generateAsync({type:"blob"})
    .then(function(content) {
        saveAs(content, "book.zip");
    });
}

export {downloadBook, generateChapterManifest}