import React, {Component} from "react";
import {getNextSibling} from "../utils";

class PageItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      words: []
    };
    this.previewRf = React.createRef();

  }

  changeText = (e) => {
    const {item} = this.props;
    let text = e.target.value;
    item.text = text;
    let words = text.split(/[।\s?.]/)
    item.words = words.map((ob) => { return {text: ob.trim(), audio: {}}});
    this.setState({words: item.words});
  }

  onFocusTimeElement = (e, item) => {
    if (!window.currentRegion)
      return;
    e.target.value = window.currentRegion.start;
    let endElem = getNextSibling(e.target, "input");
    endElem.value = window.currentRegion.end;
    item.audio.start = window.currentRegion.start;
    item.audio.end = window.currentRegion.end;

    window.currentRegion = null;
  }

  componentDidMount() {
    const {item} = this.props;
    let reader = new FileReader();
    const that = this;
    reader.onload = function(event) {
      that.previewRf.current.src = event.target.result;
    };
    reader.readAsDataURL(item.file);
  }

  onTimeChange = (e, ob, prop) => {
    ob.audio[prop] = parseFloat(e.target.value);
  }

  playItem = (item) => {
    if (!item.audio)
      return;
    const {audio} = this.props;
    audio.current.playRange(item.audio.start, item.audio.end);
  }

  render() {
    const {item, onDelete} = this.props;
    return (
      <div className="page-item" key={item.id}>
        <div className="page-item-left pure-form">
          <div className="page-item-spread">
            <div className="pure-button title">
            {item.file.name}
            </div>
            <img ref={this.previewRf}/>
            <button className="handle pure-button">
              <span className="drag"/> Drag to re-order
            </button>
          </div>
          <textarea onChange={this.changeText} placeholder="Write something..."/>
          <div className="main-timing">
            Start Time:
            <input
              type="text"
              className="time"
              placeholder="seconds"
              onChange={(e) => this.onTimeChange(e, item, 'start')}
              onFocus={(e) => this.onFocusTimeElement(e, item)}
            />
            End Time:
            <input
              type="text"
              placeholder="seconds"
              className="time"
              onChange={(e) => this.onTimeChange(e, item, 'end')}
            />
            <div className="play" onClick={(e) => this.playItem(item)}/>
          </div>
          <div className="chapter-extras">
            <button className="delete-button dlt-page" onClick={() => onDelete()}><img src="/assets/delete.png"/></button>
          </div>
        </div>
      </div>
    )
  }

}


export default PageItem;
