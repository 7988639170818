import React from "react";
import {
  Link
} from "react-router-dom";


export default function Home() {
  return (
      <div className="App">
        <div className="content">
          <div className="chooser-holder main-chooser">
            <Link className="chooser" to='/kids'>
              <div>Kids Book</div>
            </Link>
            <Link className="chooser" to='/audio'>
              <div>Audio Book</div>
            </Link>
          </div>
        </div>
      </div>
  );
}
