import React, {Component} from "react";
import {ReactSortable} from "react-sortablejs";
import {getNextSibling, getUUIDFileName} from "../utils";


class ImageItem extends Component {

  constructor(props) {
    super(props);
    this.previewRf = React.createRef();
  }

  changeText = (e) => {
    const {item, onChangeItems} = this.props;
    item.time = e.target.value;
    onChangeItems()
  }

  componentDidMount() {
    const {item} = this.props;
    let reader = new FileReader();
    const that = this;
    reader.onload = function(event) {
      that.previewRf.current.src = event.target.result;
    };
    reader.readAsDataURL(item.file);
  }

  onFocusTimeElement = (e) => {
    if (!window.currentRegion)
      return;
    const {item, onChangeItems} = this.props;
    e.target.value = window.currentRegion.start;
    item.time = window.currentRegion.start;
    onChangeItems()
    window.currentRegion = null;
  }

  render() {
    const {item, onDelete} = this.props;
    return (
      <div className="audio-book-image-item" key={item.id}>
        <img ref={this.previewRf}/>
        <input
          type="text"
          className="time"
          placeholder="seconds"
          value={item.time}
          onFocus={(e) => this.onFocusTimeElement(e)}
          onChange={this.changeText}
          />
          <button className="delete-button dlt-img" onClick={() => onDelete()}><img src="/assets/delete.png"/></button>
      </div>
    )
  }

}

class AudioChapterItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // images: props.item.images || [],
      // preview: false
    };
    this.counter = 0;
  }

  changeText = (e) => {
    const {item, onChangeItems} = this.props;
    item.text = e.target.value;
    onChangeItems()
  }

  componentDidMount() {

  }

  onSelectImageFiles = (e) => {
    const { item, onChangeItems } = this.props;
    for (let file of e.target.files){
      item.images.push({
        id: this.counter++,
        name: getUUIDFileName(file),
        file: file,
        time: "",
      });
    }
    if (item.images.length > 0){
      item.images[0].time = "0"
    }
    e.target.value = ''
    onChangeItems()
    //this.setState({images});
    //const {item} = this.props;
    //item.images = images
  }

  playItem = () => {
    const {audioRef, item, onPlayAudio} = this.props;
    if (!item.file)
      return;
    audioRef.current.loadAudio(item.file);
    onPlayAudio();
  }

  onDeleteImage = (image) => {
    const {item, onChangeItems} = this.props;
    const newItems = item.images.filter((it) => it !== image)
    item.images = newItems;
    onChangeItems()
  }

  onSelectAudioFile = (e) => {
    const { item, onChangeItems } = this.props;
    const file = e.target.files[0]
    item.name = getUUIDFileName(file)
    item.file = file;
    onChangeItems()
  }

  previewToggle = (e) => {
    //const {preview} = this.state;
    //this.setState({preview: !preview})
    const {item, onChangeItems} = this.props;
    item.preview = !item.preview
    onChangeItems()
  }

  applySort = (newState) => {
    const {item, onChangeItems} = this.props;
    item.images = newState;
    onChangeItems()
  }

  render() {
    const {item, onDelete, onChangeItems} = this.props;
    return (
      <div className="page-item" key={item.id}>
        <div className="page-item-left pure-form">
          <div className="page-item-spread">
            <div className="pure-button title" onClick={this.playItem}>
              { !item.file && (
                <input type="file" accept="audio/mpeg,audio/mp3,audio/wav" onChange={this.onSelectAudioFile}/>
              )}
              { item.file && (
                <div>▶️ {item.file.name}</div>
              )}
            </div>
            <input type="text" value={item.text} className="input-item" onChange={this.changeText} placeholder="Chapter Name"/>
            <button className="handle pure-button"><span className="drag"/> Drag to re-order</button>
          </div>
          <div className="">
            <ReactSortable
              list={item.images}
              handle=".audio-book-image-item"
              setList={newState => this.applySort(newState)}
              className="audio-book-images"
            >
              {item.images.map(image => (
                <ImageItem key={image.id} item={image} onDelete={() => this.onDeleteImage(image)} onChangeItems={onChangeItems}/>
              ))}
            </ReactSortable>
          </div>
          <div className="chapter-extras">
            <div className="image-add-button">
              <label>
                <input type="file" multiple accept="image/x-png,image/gif,image/jpeg" onChange={this.onSelectImageFiles} />
                Add Image
              </label>
            </div>
            <div className="preview-check" style={item.preview ? {opacity: 1} : {}}>
              <label>
                <input type="checkbox" onChange={this.previewToggle} checked={item.preview}/> Preview
              </label>
            </div>
            <button className="delete-button dlt-page" onClick={() => onDelete()}><img src="/assets/delete.png"/></button>
          </div>
        </div>

      </div>
    )
  }

}


export default AudioChapterItem;
