import React, { Component } from 'react';
import '../style/app.css';
import { ReactSortable } from "react-sortablejs";
import AudioPreview from "../AudioPreview";
import {downloadBook} from './Download';
import {getUUIDFileName} from "../utils";
import PageItem from './PageItem';

class KidsBook extends Component {

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      audioShowing: false,
    };
    this.counter = 0;
    this.audioPreviewRef = React.createRef();
    this.audioWordsPreviewRef = React.createRef();
    this.mainAudioFile = null;
    this.wordsAudioFile = null;
  }

  onSelectFiles = (e) => {
    const { items } = this.state;
    for (let file of e.target.files){
      items.push({
        id: this.counter++,
        name: getUUIDFileName(file),
        file: file,
        audio: {},
        text: "",
        words: []
      });
    }

    this.setState({items});
  }

  download = (e) => {
    const {items} = this.state;
    downloadBook(items, this.mainAudioFile, this.wordsAudioFile)
  }

  onSelectMainAudioFile = (e) => {
    const file = e.target.files[0];
    this.audioPreviewRef.current.loadAudio(file);
    this.mainAudioFile =  {file, name: getUUIDFileName(file)};
    this.setState({audioShowing: true});
  }

  onSelectWordsAudioFile = (e) => {
    const file = e.target.files[0];
    this.audioWordsPreviewRef.current.loadAudio(file);
    this.wordsAudioFile = {file, name: getUUIDFileName(file)};
    this.setState({audioShowing: true});
  }

  onDeleteItem = (item) => {
    const {items} = this.state;
    const newItems = items.filter((it) => it !== item)
    this.setState({items: newItems})
  }

  render() {
    const {audioShowing} = this.state;
    return (
      <div className="App">
        <div className="audio-holder" style={{'display': audioShowing ? 'block': 'none'}}>
          <AudioPreview ref={this.audioPreviewRef} title="Main Audio" onHide={() => {}} minimizeOnHide={true}  />
          <AudioPreview ref={this.audioWordsPreviewRef} title="Words Audio" onHide={() => {}} minimizeOnHide={true}  />
          <div className="help">
            Click and drag to make a region. Double click on a region to play it. Single click to seek.
          </div>
        </div>
        <div className="content">
          <div className="main-help">
            <h3>How to</h3>
            <ul>
              <li>Select all the images using the image chooser.</li>
              <li>Select the audio file using the audio chooser.</li>
              <li>Fill in the corresponding text of the images below each image.</li>
              <li>For each sentence click and drag on the audio wave to select the correct range. You
                can double click on the range to play full range from start to finish. Single click to
                play from the clicked position.
              </li>
              <li>When the region is correctly selected, click on the box beside "Start Time". This will
              automatically fill the audio ranges.</li>
              <li>After doing this for all the images, click on "Download". This is the final book.</li>
            </ul>
          </div>
          <div className="chooser-holder">
            <div className="image-files chooser">
              <div>Image Files</div>
              <input type="file" multiple accept="image/x-png,image/gif,image/jpeg" onChange={this.onSelectFiles}/>
            </div>
            <div className="main-audio-file chooser">
              <div>Main audio file</div>
              <input type="file" accept="audio/mpeg,audio/wav" onChange={this.onSelectMainAudioFile}/>
            </div>
            {/*<div className="words-audio-file chooser">
              <div>Words audio file</div>
              <input className="" type="file" accept="audio/mpeg,audio/wav" onChange={this.onSelectWordsAudioFile}/>
            </div>*/}
          </div>
          <div className="page-items">
            <ReactSortable
              list={this.state.items}
              handle=".handle"
              setList={newState => this.setState({ items: newState })}
            >
              {this.state.items.map(item => (
                <PageItem key={item.id} item={item} audio={this.audioPreviewRef} onDelete={() => this.onDeleteItem(item)}/>
              ))}
            </ReactSortable>
          </div>
          { this.state.items.length > 0 && (
            <button className="pure-button pure-button-primary" onClick={this.download}>Download Book</button>
          )}
        </div>
      </div>
    );
  }
}


export default KidsBook;
