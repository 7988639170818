import React, { Component } from 'react';
import './style/app.css';
import './style/index.css';
import WaveSurfer from 'wavesurfer.js';
import RegionPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions';


class AudioPreview extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hasAudio: false,
      showing: true
    };
    this.waveFormRef = React.createRef();
    this.waveSurfer = null;
  }

  loadAudio = (file) => {
    if (this.waveSurfer)
      this.waveSurfer.destroy();

    this.setState({hasAudio: true});

    let waveSurfer = WaveSurfer.create({
      container: this.waveFormRef.current,
      plugins: [
        RegionPlugin.create({
          dragSelection: {
            slop: 5
          },
          color: 'hsla(200, 50%, 70%, 0.4)'
        }),
      ]
    });

    //let playStartTimeOut = null;

    waveSurfer.on('region-created', function (region) {

    });

    waveSurfer.on('seek', function (pos, e) {
      console.log("seek", pos);
    });

    waveSurfer.on('region-update-end', function (region) {
      region.play(0);
      window.currentRegion = {start: region.start, end: region.end}
    });

    waveSurfer.on('region-click', function (region, e) {
      waveSurfer.pause();
      let rect = e.target.getBoundingClientRect();
      let x = e.clientX - rect.left;
      region.play(region.start + (region.end-region.start) * x / e.target.clientWidth);
      window.currentRegion = {start: region.start, end: region.end}
      e.stopPropagation();
    });

    waveSurfer.on('region-dblclick', function (region, e) {
      waveSurfer.pause();
      waveSurfer.seekTo(region.start/waveSurfer.getDuration());
      setTimeout(function () {
        region.play(0);
      }, 300);

      window.currentRegion = {start: region.start, end: region.end}
      e.stopPropagation();
    });

    waveSurfer.on('region-out', function (region, e) {

    })

    let reader = new FileReader();

    reader.onload = function (evt) {
        let blob = new window.Blob([new Uint8Array(evt.target.result)]);
        waveSurfer.loadBlob(blob);
    };

    reader.onerror = function (evt) {
        console.error("An error ocurred reading the file: ", evt);
    };
    reader.readAsArrayBuffer(file);

    this.waveSurfer = waveSurfer;
  }

  playRange = (start, end) => {
    if (this.waveSurfer == null){
      return;
    }
    this.waveSurfer.play(start, end);
  }

  play = (e) => {
    this.waveSurfer.play();
  }

  pause = (e) => {
    this.waveSurfer.pause();
  }

  stop = (e) => {
    this.waveSurfer.destroy();
  }

  zoom = (e) => {
    this.waveSurfer.zoom(Number(e.target.value));
  }

  clearSelections = (e) => {
    this.waveSurfer.clearRegions();
  }

  onHide = (e) => {
    const {showing} = this.state;
    const {onHide, minimizeOnHide} = this.props;
    if (minimizeOnHide){
      this.setState({showing: !showing})
    }
    if (onHide){
      onHide();
    }
  }

  render() {
    const {hasAudio, showing} = this.state;
    const {title} = this.props;
    return (
        <div className="audio-preview">
          { hasAudio && (
            <div className="title">
              {title}
              <button className="pure-button toggle" onClick={this.onHide}>
                { showing ? "Hide": "Show"}
              </button>
              { hasAudio && (

                <div className="options">
                  <input className="zoom" type="range" min="0" max="200" onChange={this.zoom}/>
                  <button className="pure-button" onClick={this.play}>Play</button>
                  <button className="pure-button" onClick={this.pause}>Pause</button>
                  <button className="pure-button" onClick={this.clearSelections}>Clear</button>
                </div>
              )}
            </div>
          )}
          <div className="audio-details" style={{'display': showing ? 'block': 'none'}}>
            <div ref={this.waveFormRef}/>
          </div>
        </div>
    );
  }
}

export default AudioPreview;
