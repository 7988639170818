import JSZip from "jszip";
import {saveAs} from 'file-saver';
import {fromBlob} from "image-resize-compress";
import {getAudioFileDuration} from "../utils";


async function generateBookManifest(title, chapters, preview) {

  let book = {
    title: title,
    chapters: []
  }

  for (const chapter of chapters) {
    let status;
    if (!preview){ // normal file
      status = chapter.file ? "available" : "upcoming";
    } else { // preview dl
      status = (chapter.file && chapter.preview) ? "available" : "locked"
    }

    let audio = {
      file: status === "available" ? `audios/${chapter.name}` : null,
      images: [],
      status: status,
      duration: 0
    };

    book.chapters.push({
      title: chapter.text,
      audios: [audio]
    });

    if (status !== "available"){
      continue
    } else {
      const duration = await getAudioFileDuration(chapter.file)
      audio.duration = parseInt(duration);
    }

    chapter.images.forEach(function (image, i){
      const end = (i+1 < chapter.images.length) ? chapter.images[i+1].time : -1
      audio.images.push({
        image: `images/${image.name}`,
        start: parseFloat(image.time) ? parseFloat(image.time) : 0,
        end: parseFloat(end) ? parseFloat(end) : 0,
      })
    })
  }
  return book;
}


async function downloadBook(title, items, preview) {

  const zip = new JSZip();
  let images = zip.folder("images");
  let audios = zip.folder("audios");

  let promises = []

  for (let item of items) {
    if (preview && !item.preview){
      continue
    }
    audios.file(item.name, item.file);
    for(let image of item.images){
      //images.file(image.name, image.file)
      let convertPromise = fromBlob(image.file, 80, 1080, 'auto', 'jpeg').then((blob) => {
        images.file(image.name, blob)
      });
      promises.push(convertPromise);
    }
  }
  await Promise.all(promises)

  const book = await generateBookManifest(title, items, preview)

  zip.file("manifest.json", JSON.stringify(book, null, '\t'));

  zip.generateAsync({type: "blob"})
    .then(function (content) {
      saveAs(content, `${title}${ preview ? "-preview": ""}.zip`);
    });
}

export {downloadBook, generateBookManifest}
