import {v4 as uuid4} from "uuid";

function getNextSibling(elem, selector) {

	let sibling = elem.nextElementSibling;
	if (!selector) return sibling;
	while (sibling) {
		if (sibling.matches(selector)) return sibling;
		sibling = sibling.nextElementSibling
	}
  return sibling;
}

function getUUIDFileName(file) {
  return uuid4().replace('-', '') + "." + file.name.split('.').pop().toLowerCase();
}

window.URL = window.URL || window.webkitURL;

function getAudioFileDuration(file) {
	return new Promise((resolve, reject) => {
		try {
			let audio = document.createElement('audio');
			audio.preload = 'metadata';

			audio.onloadedmetadata = function () {
				window.URL.revokeObjectURL(audio.src);
				resolve(audio.duration);
			}
			audio.src = URL.createObjectURL(file);
		} catch (e) {
			reject(e)
		}
	})
}
export {getUUIDFileName, getNextSibling, getAudioFileDuration}
